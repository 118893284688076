import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseURL } from "../utility/config/config";

type PostsResponse = any;

interface AuthStatusResponse {
  status: boolean;
  message: string;
  statusCode: number;
  data: Record<string, unknown>;
}

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Use the original fetchBaseQuery for making the request
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${BaseURL}/api/v1/`,
    prepareHeaders: (headers, { getState }: any) => {
      const token = getState()?.loginSlice?.userLoginInfo?.data?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  let result = await rawBaseQuery(args, api, extraOptions);

  if ((result?.error?.data as AuthStatusResponse)?.statusCode === 401) {
    const refreshToken = api.getState().loginSlice?.userLoginInfo?.data?.refreshToken;
    const accessToken = api.getState().loginSlice?.userLoginInfo?.data?.token;

    // Try to get new tokens using the refresh token
    const refreshResult = await fetchBaseQuery({
      baseUrl: `${BaseURL}/api/v1/`,
    })(
      {
        url: "auth/refreshToken",
        method: "POST",
        body: { accessToken, refreshToken },
      },
      api,
      extraOptions,
    );

    if (refreshResult?.data) {
      api.dispatch({
        type: "loginSlice/updateTokens",
        payload: refreshResult?.data,
      });

      // Retry the original request with the new token
      result = await fetchBaseQuery({
        baseUrl: `${BaseURL}/api/v1/`,
        prepareHeaders: (headers, { getState }: any) => {
          const token = getState()?.loginSlice?.userLoginInfo?.data?.token;
          if (token) {
            headers.set("authorization", `Bearer ${token}`);
          }
          return headers;
        },
      })(args, api, extraOptions);
    } else {
      // Refresh token failed, log out the user
      api.dispatch({ type: "loginSlice/logout" });
    }
  }

  return result;
};

export const taxdApi = createApi({
  reducerPath: "taxdApi",
  baseQuery: baseQueryWithReauth, // Use the custom baseQuery with 401 handling
  tagTypes: ["formsData"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ patch, role }) => ({
        url: `auth/login?role=${role}`,
        method: "POST",
        body: patch,
      }),
    }),
    register: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/register",
        method: "POST",
        body: patch,
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/verifyOtp",
        method: "POST",
        body: patch,
      }),
    }),
    resendOtp: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/resendOtp",
        method: "PUT",
        body: patch,
      }),
    }),
    forgotPassword: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/forgetPassword",
        method: "POST",
        body: patch,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ patch }) => ({
        url: "auth/resetPassword",
        method: "POST",
        body: patch,
      }),
    }),
    countryListAPI: builder.query<PostsResponse, void>({
      query: () => "phoneNumbervalidation",
    }),
    getAssessment: builder.query<PostsResponse, string | undefined>({
      query: (assessmentYear) =>
        `selfAssessment?assessment_type=SELF_ASSESSMENT&assessment_year=${assessmentYear || localStorage.getItem("assesment_year")}`,
      providesTags: ["formsData"],
    }),
    getHmrcAuthStatus: builder.query<AuthStatusResponse, void>({
      query: () => "hmrc/isAuthorised",
    }),
    assessment: builder.mutation({
      query: ({ patch, assesment_id, subFormId }) => ({
        url: `selfAssessment?assessment_id=${assesment_id}&subFormId=${subFormId}`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: ["formsData"],
    }),
    assessmentSubForm: builder.mutation({
      query: ({ assesment_id, questionKey }) => ({
        url: `selfAssessment/subForm?assessment_id=${assesment_id}&questionKey=${questionKey}`,
        method: "POST",
      }),
      invalidatesTags: ["formsData"],
    }),
    deleteAssessmentSubForm: builder.mutation({
      query: ({ assesment_id, questionKey, subFormId }) => ({
        url: `selfAssessment/subForm?assessment_id=${assesment_id}&questionKey=${questionKey}&subFormId=${subFormId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["formsData"],
    }),
    hmrcTokenAuth: builder.mutation({
      query: ({ patch }) => ({
        url: "hmrc/oauth/token",
        method: "POST",
        body: patch,
      }),
    }),
    getAssessmentProgress: builder.query<PostsResponse, void>({
      query: () => "assessmentProgress?assessment_type=SELF_ASSESSMENT",
      providesTags: ["formsData"],
    }),
    adminOverviewCounts: builder.query<PostsResponse, void>({
      query: () => "admin/count",
    }),
    businessListing: builder.query<PostsResponse, { pageNo: number; limit: number }>({
      query: ({ pageNo, limit }) => `admin/businessList?pageNo=${pageNo}&limit=${limit}`,
    }),
    accountantsListing: builder.query<PostsResponse, { pageNo: number; limit: number }>({
      query: ({ pageNo, limit }) => `admin/accountantsList?pageNo=${pageNo}&limit=${limit}`,
    }),
    // get user dashboard details
    userDetails: builder.query<PostsResponse, void>({
      query: () => "dashboard/intialDashboard",
    }),
    // get important events details
    eventDetails: builder.query<PostsResponse, void>({
      query: () => "events",
    }),
    // get assessment status details for admin
    assessmentStatus: builder.query<PostsResponse, void>({
      query: () => "assessmentStatus",
    }),
    // list all business for admin dropdown
    allBusinessListing: builder.query<PostsResponse, void>({
      query: () => "admin/businessList",
    }),
  }),
});

export const {
  useAssessmentMutation,
  useGetAssessmentQuery,
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetAssessmentProgressQuery,
  useCountryListAPIQuery,
  useGetHmrcAuthStatusQuery,
  useHmrcTokenAuthMutation,
  useAdminOverviewCountsQuery,
  useBusinessListingQuery,
  useAssessmentSubFormMutation,
  useDeleteAssessmentSubFormMutation,
  useAccountantsListingQuery,
  useUserDetailsQuery,
  useEventDetailsQuery,
  useAssessmentStatusQuery,
  useAllBusinessListingQuery,
} = taxdApi;
