import { lazy, Suspense } from "react";
import "./App.css";
import "react-phone-number-input/style.css";
import { Box, useMantineTheme } from "@mantine/core";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ROUTES, USER_TYPE } from "./utility/constant";
import CustomerProfile from "./pages/customer/customer-profile/CustomerProfile";
import {
  accountantNavbarLinkData,
  admiNavbarLinkData,
  customerNavbarLinkData,
} from "./utility/assets/jsonData";
import AuthRoutes from "./component/auth-routes/AuthRoutes";
/* The code you provided is using lazy loading in a React application. Lazy loading is a technique used
to improve performance by splitting the code into smaller chunks and loading them only when they are
needed. */

const Logo = lazy(() => import("./component/logo/Logo"));
const Loading = lazy(() => import("./component/loading/Loading"));
const UserProfile = lazy(() => import("./component/user-profile/UserProfile"));
const CommonNavBar = lazy(() => import("./component/common-navBar/CommonNavBar"));
const Login = lazy(() => import("./pages/login/Login"));
const SignUp = lazy(() => import("./pages/sign-up/SignUp"));
const Assessment = lazy(() => import("./pages/assessment/Assessment"));
const CustomerOverView = lazy(() => import("./pages/customer/over-view/CustomerOverView"));
const PersonalTax = lazy(() => import("./pages/customer/personal-tax/PersonalTax"));
const Settings = lazy(() => import("./pages/customer/settings/Settings"));
const InsightsGuides = lazy(() => import("./pages/customer/insights-and-guides/InsightsGuides"));
const LimitedCompany = lazy(() => import("./pages/customer/limited-company/LimitedCompany"));
const AdminOverView = lazy(() => import("./pages/admin/admin-over-view/AdminOverView"));
const AdminBussiness = lazy(() => import("./pages/admin/admin-businesses/AdminBussiness"));
const AdminAccountants = lazy(() => import("./pages/admin/admin-accountants/AdminAccountants"));
const AdminCustomers = lazy(() => import("./pages/admin/admin-customers/AdminCustomers"));
const AdminSettings = lazy(() => import("./pages/admin/admin-settings/AdminSettings"));
const AdminAssessmentStatus = lazy(
  () => import("./pages/admin/admin-assessment-status/AdminAssessmentStatus"),
);
const AdminProfile = lazy(() => import("./pages/admin/admin-profile/AdminProfile"));
const AdminParterships = lazy(
  () => import("./pages/admin/admin-client/admin-parterships/AdminParterships"),
);
const AdminCompany = lazy(() => import("./pages/admin/admin-client/admin-company/AdminCompany"));
const AdminIndividuals = lazy(
  () => import("./pages/admin/admin-client/admin-individuals/AdminIndividuals"),
);
const AdminInsightsAndGuides = lazy(
  () => import("./pages/admin/admin-insights-and-guides/AdminInsightsAndGuides"),
);
const AccountantOverview = lazy(
  () => import("./pages/accountant/accountant-overview/AccountantOverview"),
);
const PartnershipTax = lazy(() => import("./pages/customer/partnership-tax/PartnershipTax"));
const OtpVerification = lazy(() => import("./pages/verify-otp/VerifyOtp"));
const ForgotPassword = lazy(() => import("./pages/forgot-password/ForgotPass"));
const LandingPage = lazy(() => import("./pages/landing-page/Landing"));
const IntermediateTaxPage = lazy(
  () => import("./pages/intermediate-tax-page/IntermediateLandingPage"),
);
const AdminLoginPage = lazy(() => import("./pages/admin/admin-login/AdminLogin"));
const TermsPage = lazy(() => import("./pages/login/TermsAndPolicy"));

const App = () => {
  const theme = useMantineTheme();
  return (
    <Box className="App" bg={theme.white[0]}>
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            {/** Public Routes */}
            <Route path="*" element={<Navigate to={ROUTES.LANDING} replace />} />
            <Route path={ROUTES.LANDING} element={<LandingPage />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.SIGNUP} element={<SignUp />} />
            <Route path={ROUTES.VERIFY_OTP} element={<OtpVerification />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.ADMIN_LOGIN} element={<AdminLoginPage />} />
            <Route path={ROUTES.PRIVACY_POLICY} element={<TermsPage />} />

            {/** Protected Routes */}
            <Route element={<AuthRoutes />}>
              <Route path={ROUTES.ASSESSMENT} element={<Assessment />} />
              <Route
                element={
                  <CommonNavBar
                    userType={USER_TYPE.CUSTOMER}
                    navbarLinkData={customerNavbarLinkData}
                    navBarHeader={<Logo />}
                    navBarFooter={<UserProfile route={ROUTES.CUSTOMERP_ROFILE} />}
                  />
                }>
                <Route path={ROUTES.INTERMEDIATE_TAX_PAGE} element={<IntermediateTaxPage />} />
                <Route path={ROUTES.OVERVIEW} element={<CustomerOverView />} />
                <Route path={ROUTES.CUSTOMERP_ROFILE} element={<CustomerProfile />} />
                <Route path={ROUTES.PERSONAL_TAX} element={<PersonalTax />} />
                <Route path={ROUTES.PARTNERSHIP_TAX} element={<PartnershipTax />} />
                <Route path={ROUTES.SETTINGS} element={<Settings />} />
                <Route path={ROUTES.INSIGHTS_GUIDES} element={<InsightsGuides />} />
                <Route path={ROUTES.LIMITED_COMPANY} element={<LimitedCompany />} />
              </Route>
            </Route>
            {/* Admin Routes */}
            <Route element={<AuthRoutes />}>
              <Route
                element={
                  <CommonNavBar
                    userType={USER_TYPE.ADMIN}
                    navbarLinkData={admiNavbarLinkData}
                    navBarHeader={<Logo />}
                    navBarFooter={<UserProfile route={ROUTES.ADMIN_PROFILE} />}
                  />
                }>
                <Route path={ROUTES.ADMIN_OVERVIEW} element={<AdminOverView />} />
                <Route path={ROUTES.ADMIN_BUSINESSES} element={<AdminBussiness />} />
                <Route path={ROUTES.ADMIN_ACCOUNTANTS} element={<AdminAccountants />} />
                <Route path={ROUTES.ADMIN_CUSTOMERS} element={<AdminCustomers />} />
                <Route path={ROUTES.ADMIN_SETTINGS} element={<AdminSettings />} />
                <Route path={ROUTES.ADMIN_ASSESSMENT_STATUS} element={<AdminAssessmentStatus />} />
                <Route path={ROUTES.ADMIN_PROFILE} element={<AdminProfile />} />
                <Route path={ROUTES.ADMIN_INDIVIDUALS} element={<AdminIndividuals />} />
                <Route path={ROUTES.ADMIN_COMPANY} element={<AdminCompany />} />
                <Route path={ROUTES.ADMIN_PARTERSHIPS} element={<AdminParterships />} />
                <Route
                  path={ROUTES.ASMIN_INSIGHTS_AND_DUIDES}
                  element={<AdminInsightsAndGuides />}
                />
              </Route>
            </Route>
            {/* Accountant Routes */}
            <Route
              element={
                <CommonNavBar
                  userType={USER_TYPE.ACCOUNTANT}
                  navbarLinkData={accountantNavbarLinkData}
                  navBarHeader={<Logo />}
                  navBarFooter={<UserProfile route={ROUTES.ADMIN_PROFILE} />}
                />
              }>
              <Route path={ROUTES.ACCOUNTANT_OVERVIEW} element={<AccountantOverview />} />
              <Route path={ROUTES.ACCOUNTANT_TAX_FILLING} element={<AdminOverView />} />
              <Route path={ROUTES.ACCOUNTANT_CHAT_HISTORY} element={<AdminOverView />} />
              <Route path={ROUTES.ACCOUNTANTA_INSIGHTS_GUIDES} element={<AdminOverView />} />
              <Route path={ROUTES.ACCOUNTANTA_SETTINGS} element={<AdminOverView />} />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </Box>
  );
};

export default App;
