import { BusinessData } from "../../../types/BusinessData";
import { NavbarLinkData } from "../../../types/NavbarLinkData";
import { CONSTANT, ROUTES } from "../../constant";
import menuIcon from "../image/menuIcon.svg";
import PersonalTaxImg from "../image/personalTax.svg";
import personLC from "../image/lcPerson.svg";
import personsPT from "../image/ptPersons.svg";
import iagIcon from "../image/iag.svg";
import settingsIcon from "../image/settingsIcon.svg";
import taxFile from "../image/taxFile.svg";

/* The `admiNavbarLinkData` constant is an array of objects that represent the navigation links for the
admin section of a website or application. Each object in the array contains a `link` property which
specifies the route to navigate to and a `label` property which represents the text to display for
that link. */
export const admiNavbarLinkData = [
  {
    link: ROUTES.ADMIN_OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: menuIcon,
  },
  {
    link: ROUTES.ADMIN_BUSINESSES,
    label: CONSTANT.BUSINESSES,
    icon: PersonalTaxImg,
  },
  {
    link: ROUTES.ADMIN_ACCOUNTANTS,
    label: CONSTANT.ACCOUNTANTS,
    icon: personLC,
  },
  // {
  //   link: ROUTES.ADMIN_CLIENTS,
  //   label: CONSTANT.CLIENTS,
  //   icon: personsPT,
  //   links: [
  //     { label: "Individuals", link: ROUTES.ADMIN_INDIVIDUALS, icon: personsPT },
  //     { label: "Company", link: ROUTES.ADMIN_COMPANY, icon: personsPT },
  //     { label: "Partnerships", link: ROUTES.ADMIN_PARTERSHIPS, icon: personsPT },
  //   ],
  // },
  {
    link: ROUTES.ADMIN_CUSTOMERS,
    label: CONSTANT.CUSTOMERS,
    icon: personsPT,
  },
  // {
  //   link: ROUTES.ASMIN_INSIGHTS_AND_DUIDES,
  //   label: CONSTANT.INSIGHTS_AND_DUIDES,
  //   icon: iagIcon,
  // },
  {
    link: ROUTES.ADMIN_SETTINGS,
    label: CONSTANT.SETTINGS,
    icon: settingsIcon,
  },
];

/* The `customerNavbarLinkData` constant is an array of objects that represent the navigation links for
the customer section of a website or application. Each object in the array contains a `link`
property which specifies the route to navigate to and a `label` property which represents the text
to display for that link. */
export const customerNavbarLinkData: NavbarLinkData[] = [
  {
    link: ROUTES.OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: menuIcon,
  },
  {
    link: ROUTES.INTERMEDIATE_TAX_PAGE,
    label: CONSTANT.PERSONAL_TAX,
    icon: personLC,
  },
  {
    link: ROUTES.LIMITED_COMPANY,
    label: CONSTANT.LIMITED_COMPANY,
    icon: PersonalTaxImg,
  },
  {
    link: ROUTES.PARTNERSHIP_TAX,
    label: CONSTANT.PARTNERSHIP_TAX,
    icon: personsPT,
  },
  // {
  //   link: ROUTES.INSIGHTS_GUIDES,
  //   label: CONSTANT.INSIGHTS_GUIDES,
  //   icon: iagIcon,
  // },
  // {
  //   link: ROUTES.SETTINGS,
  //   label: CONSTANT.SETTINGS,
  //   icon: settingsIcon,
  // },
];

/* The `accountantNavbarLinkData` constant is an array of objects that represent the navigation links
for the accountant section of a website or application. Each object in the array contains a `link`
property which specifies the route to navigate to and a `label` property which represents the text
to display for that link. */
export const accountantNavbarLinkData = [
  {
    link: ROUTES.ACCOUNTANT_OVERVIEW,
    label: CONSTANT.OVERVIEW,
    icon: menuIcon,
  },
  {
    link: ROUTES.ACCOUNTANT_TAX_FILLING,
    label: CONSTANT.TAX_FILLING,
    icon: taxFile,
  },
  {
    link: ROUTES.ACCOUNTANT_CHAT_HISTORY,
    label: CONSTANT.CHAT_HISTORY,
    icon: personsPT,
  },
  {
    link: ROUTES.ACCOUNTANTA_INSIGHTS_GUIDES,
    label: CONSTANT.INSIGHTS_GUIDES,
    icon: iagIcon,
  },
  {
    link: ROUTES.ACCOUNTANTA_SETTINGS,
    label: CONSTANT.SETTINGS,
    icon: settingsIcon,
  },
];
